import { Component } from '@angular/core';
import { appConfig } from '../../../app.config';

@Component({
  selector: 'recrewt-toolbar-layout',
  templateUrl: './toolbar-layout.component.html',
  styleUrls: ['./toolbar-layout.component.scss'],
})
export class ToolbarLayoutComponent {
  logoWithName = 'assets/images/RC_Logo_white.svg';

  showBrowser = !appConfig.modules.browser.disabled;

  showMatching = !appConfig.modules.matching.disabled;
}
