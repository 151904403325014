import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocType, FileDataDTO, Realm } from '@data/file/file.model';

@Injectable({
  providedIn: 'root',
})
export class IonosService {
  private url = `https://api.recrewt.de:3000/api/web/files`;

  constructor(private http: HttpClient) {}

  meta(realm: Realm, type: DocType, id: string): Observable<FileDataDTO[]> {
    return this.http
      .get<{ files: FileDataDTO[] }>(`${this.url}/${realm}/${type}/${id}`)
      .pipe(map((value) => value.files));
  }

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.http.get(`${this.url}/${realm}/${type}/${id}/${name}`, {
      responseType: 'blob',
    });
  }
}
