import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { ImportedJob, Job } from '@data/job/job.model';
import { MatchStatisticNames, MatchStatus } from '@data/match/match.model';
import { appConfig } from '../../../app.config';
import { EMPTY } from 'rxjs';
import { ImportedCompany, JobData } from '@data/imported-company/imported-company.model';
import { ImportedFairBooth } from '@data/imported-fair-booth/imported-fair-booth.model';
import { ContextMode } from '@core/enums/context-mode';

@Injectable({
  providedIn: 'root',
})
export class JobService extends EntityCrudService<Job, Job> {
  constructor(http: HttpClient) {
    super('jobs/registered', http);
  }

  incrementStat(jobId: string, status: MatchStatus) {
    if (!appConfig.modules.matching.trackStats) {
      return EMPTY;
    }
    const date = new Date().toISOString().slice(0, 10);
    const statistic = MatchStatisticNames[status];
    return this.http.put(`${this.URL}/stat/${jobId}/${date}/${statistic}`, null);
  }

  decrementStat(jobId: string, status: MatchStatus) {
    if (!appConfig.modules.matching.trackStats) {
      return EMPTY;
    }
    const date = new Date().toISOString().slice(0, 10);
    const statistic = MatchStatisticNames[status];
    return this.http.put(`${this.URL}/dec_stat/${jobId}/${date}/${statistic}`, null);
  }

  transformImportedJobs(companies: ImportedCompany[], fairBooths: ImportedFairBooth[]) {
    const jobs: ImportedJob[] = [];
    fairBooths.forEach((booth) => {
      const company = companies.find((it) => it.id === booth.importedCompany);
      booth.jobs.forEach((id) => {
        if (!company?.jobsData?.[id]) return;
        const job = this.transformImportedJob(company, id);
        if (!job) return;
        jobs.push(job);
      });
    });

    return jobs;
  }

  transformImportedJob(
    company: ImportedCompany | null | undefined,
    id: string,
  ): ImportedJob | null {
    if (!company) {
      return null;
    }
    const jobData: JobData | undefined = company?.jobsData?.[id];
    return {
      id,
      imported: true,
      company: company.id,
      location: company.sites?.[0]?.location,
      desc: jobData?.desc,
      mode: ContextMode.FAIR,
      applicationFlowOverride: {
        careerSite: jobData?.url,
        internshipSite: jobData?.url,
      },
      estimatedSalaryEUR: jobData?.estimatedSalaryEUR,
      contact: company.contact,
      internship_possible: jobData?.internshipPossible,
      typeid: +id.split('_')[0],
      specialization: id.includes('_') ? id : undefined,
      vacation: jobData?.vacation,
      topics: jobData?.topics,
      scholasticData: jobData?.scholasticData,
      salaryUnspecified: jobData?.salaryUnspecified,
      identifiableName: jobData?.identifiableName ?? 'Lehrstelle',
      matchData: {
        frame: 0,
        filter: {},
        additionalData: {
          benefits: (jobData?.matchData?.additionalData?.benefits as any) ?? {},
        },
        general: {
          requirements: [],
          educationLevel: jobData?.educationLevel ?? 1,
          benefits: (jobData?.matchData?.general.benefits as any) ?? {
            financial: 0,
            catering: 0,
            education: 0,
            health: 0,
            leisure: 0,
            mobility: 0,
            work: 0,
            technical: 0,
          },
        },
      },
    };
  }
}
