import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { Location } from '@data/matching-session/matching-session.model';
import * as turf from '@turf/turf';
import { appConfig } from '../../../../app.config';

@Component({
  selector: 'recrewt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges {
  map?: mapboxgl.Map;

  circleLayerId = 'circle-layer';

  style = 'mapbox://styles/mapbox/streets-v11';

  lat = 48.551687;

  lng = 12.186438;

  @Input() markers: Location[] = [];

  @Input() circleRadius?: number;

  @Input() markerColor?: string;

  ngAfterViewInit() {
    setTimeout(() => this.initMap(), 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['circleRadius'] && this.map) {
      this.drawCircle();
      this.fitBounds();
    }
  }

  setMarkers(coordinates: Location[], color?: string) {
    coordinates
      .filter((l) => l?.location?.coordinates)
      .forEach((loc) => {
        if(!this.map) return
        new mapboxgl.Marker({
          color: color || appConfig.theme.primary,
        })
          .setLngLat(loc!.location!.coordinates!)
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(loc.address!))
          .addTo(this.map);
      });
  }

  private initMap() {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.apiKey,
      antialias: true,
      container: 'map',
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat],
      scrollZoom: false,
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on('load', () => {
      this.setMarkers(this.markers, this.markerColor);
      this.drawCircle();
      this.fitBounds();
    });
  }

  private fitBounds() {
    if(!this.map) return
    const bounds = new mapboxgl.LngLatBounds();
    this.markers.forEach((loc) => {
      bounds.extend(loc!.location!.coordinates);
    });
    this.map.fitBounds(bounds, { padding: 50, maxZoom: 16, duration: 1000 });

    if (this.circleRadius && this.markers.length > 0) {
      const circle = turf.circle(this.markers[0].location.coordinates, this.circleRadius, {
        units: 'kilometers',
      });
      const circleBounds = new mapboxgl.LngLatBounds();
      circle.geometry.coordinates[0].forEach((c) => {
        circleBounds.extend([c[0], c[1]]);
      });
      this.map.fitBounds(circleBounds, { padding: 50, maxZoom: 16, duration: 1000 });
    }
  }

  private drawCircle() {
    if(!this.map) return
    if (this.map.getLayer(this.circleLayerId)) {
      this.map.removeLayer(this.circleLayerId);
      this.map.removeSource(this.circleLayerId);
    }

    const loc = this.markers[0];
    if (!this.circleRadius || !loc) {
      return;
    }

    const circle = turf.circle(loc.location.coordinates, this.circleRadius, {
      units: 'kilometers',
    });

    this.map.addLayer({
      id: this.circleLayerId,
      type: 'fill',
      source: {
        type: 'geojson',
        data: circle,
      },
      paint: {
        'fill-color': '#B42222',
        'fill-opacity': 0.3,
      },
    });
  }
}
