import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AppRouteNames } from './app.routing.names';
import { FooterOnlyLayoutComponent } from './layout/footer-only-layout/footer-only-layout.component';
import { InfoCardLayoutComponent } from './layout/info-card-layout/info-card-layout.component';
import { PrimaryBgLayoutComponent } from './layout/primary-bg-layout/primary-bg-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { JobGeneralResolver } from '@core/resolver/job-general.resolver';
import { PoweredByLayoutComponent } from './layout/powered-by-layout/powered-by-layout.component';
import { appConfig } from '../app.config';
import { PseudoAuthGuard } from '@core/guards/pseudo-auth.guard';

const homeModule: Route = {
  path: AppRouteNames.HOME,
  component: FooterOnlyLayoutComponent,
  loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
};
const storyModule: Route = {
  path: AppRouteNames.STORY,
  canActivate: [AuthGuard],
  component: PoweredByLayoutComponent,
  loadChildren: () => import('./modules/story/story.module').then((m) => m.StoryModule),
};
const browserModule: Route = {
  path: AppRouteNames.BROWSER,
  resolve: [JobGeneralResolver],
  canActivate: [AuthGuard],
  component: PoweredByLayoutComponent,
  loadChildren: () => import('./modules/browser/browser.module').then((m) => m.BrowserModule),
};
const matchingModule: Route = {
  path: AppRouteNames.MATCHING,
  resolve: [JobGeneralResolver],
  canActivate: [AuthGuard],
  component: PoweredByLayoutComponent,
  loadChildren: () => import('./modules/matching/matching.module').then((m) => m.MatchingModule),
};
const routes: Routes = [
  {
    path: '',
    redirectTo: AppRouteNames.HOME,
    pathMatch: 'full',
  },
  {
    path: AppRouteNames.ERROR,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: AppRouteNames.INFO,
    component: PrimaryBgLayoutComponent,
    loadChildren: () => import('./modules/info/info.module').then((m) => m.InfoModule),
  },
];

function configureRoutes(): Routes {
  const usedRoutes = [...routes];
  const moduleConfig = appConfig.modules;
  if (!moduleConfig.home.disabled) {
    usedRoutes.unshift(homeModule);
  }

  function addModule(module: Route) {
    if (moduleConfig.home.auth) {
      module.canActivate!.unshift(PseudoAuthGuard);
    }
    usedRoutes.unshift(module);
  }

  if (!moduleConfig.story.disabled) {
    addModule(storyModule);
  }

  if (!moduleConfig.browser.disabled) {
    addModule(browserModule);
  }

  if (!moduleConfig.matching.disabled) {
    addModule(matchingModule);
  }

  return usedRoutes;
}

const forRoot = configureRoutes();
@NgModule({
  imports: [RouterModule.forRoot(forRoot)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
