<div class="height-match-parent">
  <mat-toolbar color="primary" style="border-bottom: #e38100 1px solid">
    <mat-toolbar-row class="first-toolbar-row no-select" fxLayoutAlign="space-between center">
      <div
        class="height-match-parent width-match-parent"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
      >
        <a
          *ngIf="logoWithName"
          class="no-pad m-r-3"
          href="https://recrewt.de"
          target="_blank noreferrer noopener"
        >
          <img [src]="logoWithName" alt="Recrewt Logo" class="nav-home-logo m-t-s" />
        </a>
        <button *ngIf="showMatching" mat-button routerLink="/matching/rated" routerLinkActive="selected">
          {{ 'Matching' }}
        </button>
        <button *ngIf="showBrowser" mat-button routerLink="/browser" routerLinkActive="selected">
          {{ 'Ausbildungssuche' }}
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="toolbar-content">
    <div class="nav-content height-match-parent">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
